import type { NetlessApp } from "@netless/window-manager";
import { createRoot } from "react-dom/client";
import React from "react";
import App  from "./App";

const ScriptsShow: NetlessApp = {
    config: {
      minwidth: 0.3,
      minheight: 0.3,
      width: (9 / 16) * 0.5,
      height: 0.5,
    },
    kind: "年轮",
    setup(context) {
      const box = context.getBox();
      // @todo
      box._fixRatio$.setValue(true);
  
      // box.mountStyles(styles);
  
      const $content = document.createElement("div");
      $content.className = "show";
      box.mountContent($content);
  
      const root = createRoot($content);
      root.render(<App context={context} />);
  
      context.emitter.on("destroy", () => {
        root.unmount();
      });
    },
  };
  
  export default ScriptsShow;